export class RustJSAdapter {
  constructor() {
    this.subscribers = new Map();
    document.set_particle_positions = (positions, id) => this.call_appropriate_callback(positions, 0, id);
    document.set_colors = (colors, id) => {
      this.call_appropriate_callback(colors, 1, id);
    }
  }
  subscribe(id, callback_positions, callback_color) {
    this.subscribers.set(id, [callback_positions, callback_color]);
  }
  call_appropriate_callback(positions_or_colors, function_index, id) {
    const callback = this.subscribers.get(id);
    if (callback) {
      callback[function_index](positions_or_colors)
    }
  }
}
