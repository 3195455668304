import * as THREE from "three";
import Stats from 'stats.js'
import {
  addLights,
  createCamera,
  createGround,
  createRenderer,
  createCube,
  onWindowResize,
} from "./util.js";
import { SimulationSettings } from "./SimulationSettings.js";
import init, { Simulation as LogicSimulation } from "../../logic/pkg/logic.js";

export class Simulation {
  static number_of_simulations = 0;

  constructor(number_of_particles, dimension, bounding_box_dimensions) {
    this.id = Simulation.number_of_simulations;
    Simulation.number_of_simulations += 1;
    this.dimension = dimension;
    this.number_of_particles = number_of_particles;
    this.bounding_box_dimensions = bounding_box_dimensions;
    this.particle_positions = new Float32Array(number_of_particles * dimension);
    this.last_time = 0;
    this.frame_count = 0;
    this.simulation_settings = new SimulationSettings(this);
    this.setup_resize();
    this.setup_graphics();

    init().then(() => {
      this.logic_sim = new LogicSimulation(this.id, number_of_particles, dimension, this.bounding_box_dimensions);
    }).then(() => {
      this.animate(0);
    });
  }

  animate(timestamp) {
    this.simulation_settings?.stats.begin();
    let time_elapsed = (timestamp - this.last_time) / 1000.;
    if (time_elapsed > 0.08) {
      time_elapsed = 1. / 60;
    }
    this.frame_count++;
    this.last_time = timestamp;

    if (this.simulation_settings.should_draw_frame()) this.draw_frame();
    if (this.simulation_settings.should_update_logic()) this.logic_sim.step(time_elapsed);
    window.requestAnimationFrame(timestamp => this.animate(timestamp));
    this.simulation_settings?.stats.end();
  }

  setup_graphics() {
    this.scene = new THREE.Scene();
    addLights(this.scene);
    this.renderer = createRenderer(this.dimension)
    this.camera = createCamera(this.renderer, this.bounding_box_dimensions, this.dimension);
    createCube(this.scene, this.bounding_box_dimensions);
    createGround(this.scene, this.bounding_box_dimensions);

    this.geometry = new THREE.SphereGeometry(0.04, 7, 7);
    this.sphere_color = new THREE.Color();
    this.material = new THREE.MeshBasicMaterial({ color: 0xffffff });
    this.spheres = new THREE.InstancedMesh(this.geometry, this.material, this.number_of_particles);
    this.scene.add(this.spheres);

    this.matrix = new THREE.Matrix4();
    this.translation_vector = new THREE.Vector3();
  }

  resize_window() {
    onWindowResize(this.camera, this.renderer);
  }

  set_particle_positions(positions) {
    for (let i = 0; i < positions.length / 3; i++) {
      this.spheres.getMatrixAt(i, this.matrix);
      this.translation_vector.z = positions[i * 3];
      this.translation_vector.x = positions[i * 3 + 1];
      this.translation_vector.y = positions[i * 3 + 2];


      this.matrix.makeTranslation(this.translation_vector);

      this.spheres.setMatrixAt(i, this.matrix);
    }
    this.spheres.instanceMatrix.needsUpdate = true;
  }

  set_colors(colors) {
    for (let i = 0; i < colors.length; i++) {
      this.spheres.setColorAt(i, this.sphere_color.setHex(colors[i]));
    }
    this.spheres.instanceColor.needsUpdate = true;
  }

  draw_frame() {
    this.renderer.render(this.scene, this.camera);
  };
  setup_resize() {
    window.addEventListener(
      "resize",
      () => this.resize_window(),
      false,
    );
  }
  subscribe_to_position_and_color_changes(adapter) {
    adapter.subscribe(this.id, positions => this.set_particle_positions(positions), colors => this.set_colors(colors));
  }
}
