import { Simulation } from "./ui/Simulation.js";
import { RustJSAdapter } from "./ui/RustJSAdapter.js";
import init from "../logic/pkg/logic.js";
const toggle = document.querySelector(".toggle input");
const sim2DContainer = document.querySelector(".simulation-2D");
const sim3DContainer = document.querySelector(".simulation-3D");
let simulation2d, simulation3d;

const setDimension = () => {
  const is3D = toggle.checked;
  if (is3D) {
    simulation3d?.simulation_settings.continue();
    simulation2d?.simulation_settings.pause();
    sim2DContainer?.classList.add("hidden");
    sim3DContainer?.classList.remove("hidden");
    // toggle setting-control boxes
    document.querySelector(".controls > div:first-child")?.classList.add("hidden");
    document.querySelector(".controls > div:nth-child(2)")?.classList.remove("hidden");
  } else {
    simulation3d?.simulation_settings.pause();
    simulation2d?.simulation_settings.continue();
    sim2DContainer?.classList.remove("hidden");
    sim3DContainer?.classList.add("hidden");
    // toggle setting-control boxes
    document.querySelector(".controls > div:first-child")?.classList.remove("hidden");
    document.querySelector(".controls > div:nth-child(2)")?.classList.add("hidden");
  }
};

window.onload = async () => {
  toggle.onchange = setDimension;
  // make sure spacebar doesn't toggle checkbox
  toggle.onkeydown = e => {
    if (e.key === " " || e.keyCode === 32 || e.code === "Space") {
      e.preventDefault();
    }
  }

  const adapter = new RustJSAdapter();
  await init();
  simulation2d = new Simulation(1000, 2, [5.0, 5.0]);
  simulation2d.subscribe_to_position_and_color_changes(adapter)
  simulation3d = new Simulation(2000, 3, [5.0, 5.0, 5.0]);
  simulation3d.subscribe_to_position_and_color_changes(adapter)

  setDimension();
}
