import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

export function createRenderer(dimension) {
  const renderer = new THREE.WebGLRenderer();
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.toneMapping = THREE.ReinhardToneMapping;
  renderer.toneMappingExposure = 3;
  renderer.shadowMap.enabled = true;
  const container = document.body.querySelector(`.simulation-${dimension}D`);
  container && container.appendChild(renderer.domElement);
  return renderer;
}

export function createCamera(renderer, dimensions, dimension) {
  const camera = new THREE.PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    0.01,
    1000,
  );
  let [originX, originY, originZ] = [dimensions[0] / 2., dimensions[1] / 2., dimensions[2] / 2.];
  if (!originZ) {
    originZ = 0;
  }
  camera.lookAt(originX, originY, originZ);
  // create controls for user
  const controls = new OrbitControls(camera, renderer.domElement);
  controls.target.set(originX, originY, originZ);
  camera.position.x = 5;
  camera.position.y = 5;
  camera.position.z = 10;
  controls.update();
  return camera;
}

export function addLights(scene) {
  const ambientLight = new THREE.AmbientLight(0x404040);
  scene.add(ambientLight);
}

export function createGround(scene, dimensions) {
  // Ground plane
  const geo = new THREE.PlaneGeometry(20, 20, 8, 8);
  const mat = new THREE.MeshBasicMaterial({
    color: 0xffffff,
    side: THREE.DoubleSide,
  });
  const plane = new THREE.Mesh(geo, mat);
  plane.rotateX(-Math.PI / 2);
  plane.translateX(dimensions[1] / 2.);
  plane.translateY(-dimensions[0] / 2.);
  scene.add(plane);
}

export function createCube(scene, bounding_box_dimensions) {
  let width, height, depth;
  if (bounding_box_dimensions.length == 2) {
    [width, depth] = bounding_box_dimensions;
    height = 0.1;
  } else {
    [width, height, depth] = bounding_box_dimensions;
  }
  const geometry = new THREE.BoxGeometry(width, height, depth);
  const material = new THREE.MeshPhongMaterial({
    color: 0xffffff,
    opacity: 0.05,
    transparent: true,
  });
  const cube = new THREE.Mesh(geometry, material);
  cube.position.x = width / 2.0;
  cube.position.y = height / 2.0;
  cube.position.z = depth / 2.0;
  scene.add(cube);
  return cube;
}

export function onWindowResize(camera, renderer) {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize(window.innerWidth, window.innerHeight);
}
