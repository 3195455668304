import { GUI } from 'dat.gui'
import Stats from 'stats.js'

export class SimulationSettings {
  constructor(simulation) {
    this.simulation = simulation;
    this._mass = 0.;
    this._target_density = 0.
    this._pressure_multiplier = 0.0;
    this._radius_of_influence = 0.0;
    this._gravity = -0.0;
    this.is_paused = false;
    this.should_draw = true;
    this.setup_key_inputs();
    this.create_debug_UI();
    setTimeout(() => this.init_settings(), 100);
  }

  init_settings() {
    // let [x, y, z] = simulation.bounding_box_dimensions;
    // if (!z) z = 1;
    this.mass = 1.;
    this.target_density = 5.0;
    this.pressure_multiplier = 15.0;
    this.radius_of_influence = 1.0;
    this.gravity = -1.0;
  }

  setup_key_inputs() {
    window.addEventListener("keydown", (e) => {
      if (e.key === " " || e.code === "Space") {
        // don't draw when simulation is not even in frame 
        if (!this.should_draw) return;
        this.is_paused = !this.is_paused;
      } else if (e.key === "s") {
        this.simulation?.logic_sim?.step(1. / 60)
      } else if (e.key == "D" || e.code === "KeyD") {
        this.simulation?.logic_sim?.debug()
      }
    });
  }

  create_debug_UI() {
    this.gui = new GUI({ autoPlace: false })
    // get domElemnt where gui should be placed
    const container = document.body.querySelector(`.controls`);
    container && container.appendChild(this.gui.domElement);
    const simulationFolder = this.gui.addFolder(`Simulation ${this.simulation.dimension}D`);
    simulationFolder.add(this, 'is_paused').name('Pause simulation').listen()
    simulationFolder.add(this, 'mass', 0.1, 10.0, 0.1).name('Particle mass').listen()
    simulationFolder.add(this, 'target_density', 0.1, 200.0).name('Target density').listen()
    simulationFolder.add(this, 'pressure_multiplier', 0.1, 40.0).name('pressure mult').listen()
    simulationFolder.add(this, 'radius_of_influence', 0.1, 5.0).step(0.1).name('Radius of infl').listen()
    simulationFolder.add(this, 'gravity', -10.0, 10.0).name('Gravity')
    simulationFolder.open()

    this.stats = new Stats()
    this.stats.showPanel(0) // 0: fps, 1: ms, 2: mb, 3+: custom
    document.body.appendChild(this.stats.dom)
  }



  should_draw_frame() {
    return this.should_draw;
  }
  should_update_logic() {
    return this.should_draw && !this.is_paused;
  }
  set gravity(gravity) {
    this.simulation?.logic_sim?.update_gravity(gravity);
    this._gravity = gravity;
  }
  get gravity() {
    return this._gravity;
  }

  set mass(mass) {
    this.simulation?.logic_sim?.update_mass(mass);
    this._mass = mass;
  }

  get mass() {
    return this._mass;
  }

  set target_density(density) {
    this.simulation?.logic_sim?.update_target_density(density);
    this._target_density = density;
  }
  get target_density() {
    return this._target_density;
  }

  set pressure_multiplier(multiplier) {
    this.simulation?.logic_sim?.update_pressure_multiplier(multiplier);
    this._pressure_multiplier = multiplier;
  }

  get pressure_multiplier() {
    return this._pressure_multiplier;
  }

  set radius_of_influence(radius) {
    this.simulation?.logic_sim?.update_radius_of_influence(radius);
    this._radius_of_influence = radius;
  }

  get radius_of_influence() {
    return this._radius_of_influence;
  }


  continue() {
    this.should_draw = true;
  }
  pause() {
    this.should_draw = false;
  }

}
